import {
  Client,
  FileUpload,
  LargeFileUploadTask,
  LargeFileUploadTaskOptions,
  Range,
  UploadEventHandlers,
} from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import _ from "lodash";
import { User } from "microsoft-graph";
import { IS_PRODUCTION } from "src_common/config";
import { getFileTypeByMime } from "src_common/utils/fileHelpers";
interface MailFolder {
  id: string;
  displayName: string;
  parentFolderId: string;
  childFolderCount: number;
  unreadItemCount: number;
  totalItemCount: number;
  sizeInBytes: number;
  isHidden: boolean;
}

interface MailFoldersResponse {
  "@odata.context": string;
  value: MailFolder[];
}

interface IFile {
  readonly file: File;
  readonly filename: string;
}

let graphClient: Client;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function ensureClient(authProvider: AuthCodeMSALBrowserAuthenticationProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
  }

  return graphClient;
}

export async function getUser(
  authProvider: AuthCodeMSALBrowserAuthenticationProvider
): Promise<User> {
  ensureClient(authProvider);

  const user: User = await graphClient!.api("/me").get();

  return user;
}

async function ensureYaoMailFolderExists() {
  const folderName = "YAO";
  const mailFolders: MailFoldersResponse = await graphClient!
    .api("/me/mailFolders")
    .get();
  let folder = mailFolders.value.find(
    (item) => item.displayName.toLowerCase() === folderName.toLowerCase()
  );

  if (!_.isEmpty(folder)) {
    try {
      folder = await graphClient!.api("/me/mailFolders").post({
        displayName: folderName,
      });
    } catch (error) {
      console.error("Error creating folder", error);
      return folder?.id || null;
    }
  }
  return folder?.id || null;
}

function handleFileName(fileType: string, fileName: string): string {
  if (!fileType || !fileName) return "";
  const ext = getFileTypeByMime(fileType);
  if (!ext) {
    return fileName;
  }
  const newExt = fileName.split(".").pop() === ext ? "" : `.${ext}`;
  return `${fileName}${newExt}`;
}

const getBase64FromFileForEmail = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);

    reader.onload = () => {
      const data = reader?.result?.toString().split("base64,")[1];
      if (!data) {
        return reject(new Error("Unable to upload attachment!"));
      }

      return resolve(data);
    };
  });

type SendLargeEmailPayload = {
  message: Record<string, any>;
  attachments: IFile[];
};

async function sendLargeEmail({ attachments, message }: SendLargeEmailPayload) {
  const response = await graphClient!.api("/me/messages").post(message);

  let attachmentsCopy = attachments.slice();
  while (attachmentsCopy.length) {
    await Promise.all(
      // eslint-disable-next-line no-loop-func
      attachmentsCopy.splice(0, 1).map(async (attachment) => {
        const payload = {
          AttachmentItem: {
            attachmentType: "file",
            name: handleFileName(attachment.file.type, attachment.filename),
            size: attachment.file.size,
          },
        };

        const uploadSession = await LargeFileUploadTask.createUploadSession(
          graphClient,
          `/me/messages/${response.id}/attachments/createUploadSession`,
          payload
        );

        const progress = (range?: Range, extraCallbackParam?: unknown) => {};

        const uploadEventHandlers: UploadEventHandlers = {
          progress,
          extraCallbackParam:
            "any parameter needed by the callback implementation",
        };

        const options: LargeFileUploadTaskOptions = {
          rangeSize: 1024 * 1024,
          uploadEventHandlers,
        };

        const fileObject = new FileUpload(
          attachment.file,
          attachment.filename,
          attachment.file.size
        );

        const task = new LargeFileUploadTask(
          graphClient,
          fileObject,
          uploadSession,
          options
        );

        const uploadResult = await task.upload();

        return uploadResult;
      })
    );
    await sleep(200);
  }

  const res = await graphClient!
    .api(`/me/messages/${response.id}/send`)
    .post({});

  return res;
}

type SendEmailPayload = {
  subject: string;
  to_emails: string[];
  cc_emails?: string[];
  bcc_emails?: string[];
  email_html_body: string;
  attachments: IFile[];
  matterId?: string;
};

export async function sendMail({
  subject,
  to_emails,
  email_html_body,
  attachments,
  cc_emails,
  bcc_emails,
  matterId,
}: SendEmailPayload) {
  await ensureYaoMailFolderExists();

  const isHavingLargeAttachments =
    attachments.reduce((prev, current) => prev + current.file.size, 0) /
      1048576 >
    3; // if attachments size are greater than 3 MB

  const toRecipients = to_emails.map((e) => ({
    emailAddress: {
      address: e,
    },
  }));

  const ccRecipients =
    (cc_emails &&
      cc_emails.map((e) => ({
        emailAddress: {
          address: e,
        },
      }))) ||
    [];

  const bccRecipients =
    (bcc_emails &&
      bcc_emails.map((e) => ({
        emailAddress: {
          address: e,
        },
      }))) ||
    [];

  let files;
  if (!isHavingLargeAttachments) {
    files = await Promise.all(
      attachments.map(async (a) => {
        const contentBytes = await getBase64FromFileForEmail(a.file);

        return {
          contentBytes,
          name: handleFileName(a.file.type, a.filename),
          contentType: a.file.type,
          size: a.file.size,
          "@odata.type": "#microsoft.graph.fileAttachment",
        };
      })
    );
  }

  const message = {
    subject,
    body: { contentType: "HTML", content: email_html_body },
    toRecipients,
    ccRecipients,
    bccRecipients,
    attachments: files,
  };

  // TO-DO refine the sending message to specific folder
  // if (folderId) {
  //   try {
  //     const createdMessage = await graphClient!
  //       .api(`/me/mailFolders/${folderId}/messages`)
  //       .post(message);
  //     if (createdMessage && createdMessage.id) {
  //       await graphClient!
  //         .api(`/me/messages/${createdMessage.id}/send`)
  //         .post({});
  //     }
  //     return createdMessage;
  //   } catch (error) {
  //     console.error("Error sending email", error);
  //     throw error;
  //   }
  // }

  try {
    const response = isHavingLargeAttachments
      ? await sendLargeEmail({
          message: { ...message, attachments: undefined },
          attachments,
        })
      : await graphClient!.api("/me/sendMail").post({ message });

    return response;
  } catch (error) {
    console.error("Error sending email", error);
    throw error;
  }
}
